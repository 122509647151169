.firstImg {
    width: 668px;
    height: 501px;
}

.firstImgPera {
    margin-left: 20px;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 180%;
    margin-right: 20px;
    /* or 43px */

    text-align: justify;
    letter-spacing: 0.05em;
    color: #1E2452;
}

.nexttechSpan {
    font-weight: 700;
    color: #F68920;
    font-size: 26px;

}

.xlogo {
    color: #1E2452;
}

.secoundImg {
    width: 520px;
    height: 388px;
}

.thirdImg {
    width: 459px;
    height: 342px;
}

.missonVisson {
    font-style: normal;
    font-family: 'Heebo', sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 100%;
    /* identical to box height, or 64px */

    /* Primary Blue */

    color: #1E2452;
}

.fouthPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 100%;
    margin-bottom: 10px;
    /* identical to box height, or 64px */



    /* Primary Blue */

    color: #1E2452;
}

.slideDes {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: left;
    letter-spacing: 0.05em;

    color: #666666;
}

.slideName {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    text-align: center;
    letter-spacing: 0.05em;

    color: #333333;
}

.subName {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-align: center;
    letter-spacing: 0.05em;

    color: #777777;
}

.slideBackGround {
    background: #F3F9FD;
    border-radius: 5px;
}


.blockquote{
    font-size: 1.2rem;
    font-style: italic;
    border-left: 2px solid #ccc;
    margin: 0;
    padding-left: 1rem;
}

.blockquote span{
    display: inline-block;
    margin: 0 0.5rem;
    color: orange;
}

@media only screen and (max-width:588px) {
    .firstImgPera {
        font-size: 16px;
        width: 200px;
       
    }

    .firstImg {
        width: 300px;
        height: 230px;
    }

    .secoundImg {
        width: 300px;
        height: 230px;

    }

    .missonVisson {
        font-size: 30px;
    }

    .fouthPera {
        font-size: 30px;
    }

}

@media only screen and (min-width: 588px) and (max-width:992px) {}

@media (prefers-color-scheme: dark) {

    .firstImgPera,
    .missonVisson,
    .fouthPera,
    .xlogo {
        color: white;
    }
}