.navIndex {
    position: sticky;
    position: fixed;
    top: 0;
    z-index: 999;
}

.navLogo {
    width: 170px;
    height: 65px;
}

.scroolNav {
    position: sticky;
    position: fixed;
    width: 2045px;
    z-index: 999;

}
.col{
    color: black;
}


@media only screen and (max-width:588px) {
    .scroolHidden{
        display: none;
    }
  
  }
  
@media only screen and (min-width: 588px) and (max-width:992px) {

    .scroolHidden{
        display: none;
    }
}
