.ServiceAllBackground {
    position: relative;
    background-image: url(https://i.ibb.co/nDsw6mb/service-Cover-Three.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
  }
  
  .ServiceAllBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
    border-radius: 12px;
    /* Adjust the opacity as needed */
  }


.ServiceImg:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: inline-block;
    background: #1E2452;
    opacity: 0.7;
    /* W3C */
}

.ServiceHeadingMainOne{
    position: relative;
    font-family: 'Heebo', sans-serif;
    font-weight: 800;
    font-size: 50px;
    line-height: 30px;
    color: white;
    z-index: 1;
  }
  .ServicePera{
    position: relative;
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 2px;
    color: white;
    z-index: 1;
  }
  
  
.imgBackground:after{

    border-radius: 12px;
    display: inline-block;
    background: #1E2452;
    opacity: 0.5;
}

.textHeading {
    color: rgb(255, 255, 255);
    font-size: 80px;
    font-weight: bold;
    font-family: 'Heebo', sans-serif;
}

.slideHeadingTwo {
    top: 75%;
    left: 10%;
}

.imagePera {
    top: 25%;
    left: 10%;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    font-family: 'Heebo', sans-serif;


    color: #FFFFFF;

}

.imageButton {
    top: 70%;
    left: 10%;
}

.ServiceImg {
    width: 1440px;
    height: 400px;
}

.OurServiceRec {
    width: 1200px;
    height: 528.86px;
}

.serviceHeading {
    font-family: 'Heebo', sans-serif;

    font-weight: lighter;
    font-size: 64px;
    line-height: 100%;
    /* identical to box height, or 64px */


    /* Primary Blue */

    color: #1E2452;

}

.Ourservicepera {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    /* or 42px */

    text-align: justify;

    color: #444444;
}

.bannerText {
    font-weight: 800;
    font-size: 64px;
    line-height: 100%;
    /* identical to box height, or 84px */


    /* Primary Blue */

    color: #1E2452;
}

.progressMianWrapper {
    background-color: black;
    height: 15px;
    position: sticky;
    z-index: 1000;
    width: 100%;
}

.progressMainStyle {
    height: 15px;
    background-color: blue;
    position: sticky;
}

.cardLogo {
    width: 100px;
    height: 100px;
}

.marginslider {
    margin-top: 200px;
}

.toobal {
    position: sticky;
    margin-top: 220px;
    margin-bottom: 100px;
}

.slidePic {
    width: 500px;
    height: 300px;
}

/* 2nd banner start*/
.bannerFont {

    color: #1E2452;
    font-size: 80px;
    font-weight: bold;
    font-family: 'Heebo', sans-serif;
}

.slideImg {
    width: 415px;
    height: 415px;
}

/* 2nd banner end*/

.aboutText {
    color: #1E2452;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 100%;
    font-family: 'Heebo', sans-serif;
    margin-top: 100px;
}

.aboutImg {
    width: 200px;
    height: 200px;
}

.cicleText {
    color: #1E2452;
    font-weight: 800;
    font-size: 64px;

    /* or 64px */


    /* Primary Blue */

    color: #1E2452;
}

.circle {
    width: 100px;
    height: 100px;


    background: #F79536;
}

.circlePera {
    font-family: 'Heebo', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    /* or 24px */


    /* Primary Blue */

    color: #1E2452;
}

.CircleIcon {
    color: #EA821E;
}

.tieckPera {
    font-family: 'Heebo', sans-serif;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */


    /* Primary Blue */

    color: #1E2452;

}

.slideBg {
    background: #F4F9FB;
    
}

.TogetherCardTitle {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    
    /* or 30px */
    background: #F79536;
   

    color: #FFFFFF;
}

.TogetherCardTitleTwo {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 115%;
    /* or 34px */


    /* Primary Blue */

    color: #1E2452;
}

.aboutIcon {
    width: 70px;
}

.TogetherCardTitleThree {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    /* or 30px */
    background: #1E2452;
    text-transform: capitalize;

    color: #FFFFFF;
}

.ItImg {
    width: 636px;
    height: 357px;
}

.weOffer {
    color: rgb(255, 255, 255);
    font-size: 80px;
    font-weight: lighter;
    font-family: 'Heebo', sans-serif;
}

.nextLvl {
    font-family: 'Heebo', sans-serif;
    color: #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}
.slideImg {
    width: 415px;
    height: 415px;
}
.TogetherCardTitle {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* or 30px */
    background: #F79536;
    text-transform: capitalize;

    color: #FFFFFF;
}


@media only screen and (max-width:588px) {
    .ServiceAllBackground{
        width: 500px;
        height: 350px;
    }
    .ServiceHeadingMainOne{
        font-size: 40px;
       text-align: center;
    }
    .ServicePera{
        font-size: 16px;
        text-align: center;
        line-height: 20px;
    }
   .ServiceImg{
    margin-top: 100px;
    width: 5880px;
    height: 100px;
   }
   .textHeading {
    margin-top: 10px;
    font-size: 40px;
    margin-bottom: 20px;
  
}
.weOffer {
    margin-top: 10px;
    font-size: 40px;
  
}
.aboutText {
    color: #1E2452;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 100%;
    font-family: 'Heebo', sans-serif;
    margin-top: 100px;
}
.ItImg {
    width: 336px;
    height: 220px;
}
.TogetherCardTitle {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    margin-left: 20px;
    /* or 30px */
    background: #F79536;
    text-transform: capitalize;

    color: #FFFFFF;
}
.TogetherCardTitleTwo {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 115%;
    margin-left: 40px;
    /* or 34px */


    /* Primary Blue */

    color: #1E2452;
}

}

@media only screen and (min-width: 588px) and (max-width:992px) {
    .ServiceAllBackground{
        width: 900px;
        height: 550px;
    }
    .ServiceHeadingMainOne{
        font-size: 60px;
       text-align: center;
    }
    .ServicePera{
        font-size: 20px;
        text-align: center;
        line-height: 30px;
    }
    .ServiceImg{
        width: 800px;
        height: 200px;
       }
       .textHeading {
        margin-top: 0px;
        font-size: 50px;
        margin-bottom: 20px;
      
    }
    .weOffer {
        margin-top: 10px;
        font-size: 80px;
      
    }
}

@media (prefers-color-scheme: dark) {
  .aboutText, .nextLvl, .cicleText, .circlePera, .tieckPera, .TogetherCardTitleTwo{
    color: white;
  }
  }