.ContactAllBackground {
    position: relative;
    background-image: url(https://i.ibb.co/DpPqmRq/contact-Two.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
  }
  
  .ContactAllBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
    border-radius: 12px;
    /* Adjust the opacity as needed */
  }

  .ContactHeadingMainOne{
    position: relative;
    font-family: 'Heebo', sans-serif;
    font-weight: 800;
    font-size: 50px;
    line-height: 35px;
    color: white;
    z-index: 1;
  }


.emailCard {

    width: 1340px;
    height: 536px;


    background: #40456C;
    box-shadow: 0px 4px 30px 9px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.emialcardText {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 115%;
    /* or 55px */


    color: #FFFFFF;
}

.emialCardPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 115%;
    /* or 32px */


    color: #FFFFFF;
}

.ourTeam {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 100%;
}

.usBox {
    background: #EFF2F6;
    border-radius: 5px;
    height: 354px;
}

.usCardHeading {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    /* or 32px */


    color: #333333;
}

.usPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    /* or 27px */

    letter-spacing: 0.05em;

    color: #333333;
}

.usNumber {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* or 27px */

    letter-spacing: 0.05em;

    color: #333333;
}
.contactImg:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
   
    display: inline-block;
    background: #1E2452;
    opacity: 0.5;
    /* W3C */
}
.contactImg{
    width: 1340px;
}



@media only screen and (max-width:588px) {
    .ContactAllBackground {
        width: 300px;
        height: 300px;
     
      }
      .ContactHeadingMainOne{
        font-size: 26px;
        line-height: 88px;
        color: white;
        text-align: center;
        z-index: 1;
      }
      .emialcardText {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 115%;
        /* or 55px */
    
    
        color: #FFFFFF;
    }
    
.emailCard{
    width: 370px;
    height: 1000px;
}
.usBox{
    width: 370px;
    height: 800px;
    padding: 10px;
}
}

@media only screen and (min-width: 588px) and (max-width:992px) {
 
    .emailCard{
     
        height: 1000px;
    }
    .usBox{
    
        height: 800px;
        padding: 10px;
    }
    .ContactAllBackground {
        width: 500px;
        height: 350px;
      }
      .ContactHeadingMainOne{
        font-size: 36px;
        line-height: 88px;
        text-align: center;
        color: white;
        z-index: 1;
      }
}