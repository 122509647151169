.OurCourse {
  font-family: 'Heebo', sans-serif;
  font-weight: 800;
  font-size: 64px;
  line-height: 100%;
  text-align: center;
  color: #1E2452;
}
.courseBackground{
  background-color: #F3F8FB;
}

.CourseArea {
  width: 1200px;
  height: 528.86px;
}

.CourseImgSize {
  width: 200px;
  height: 200px;
}

.courseBannerImg:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: inline-block;
  background: #1E2452;
  opacity: 0.5;
  /* W3C */
}

.courseBannerImg {
  width: 1440;
  height: 200;
}

.courseCard {
  width: 350px;
  height: 420px;
}

.courseCardImg {
  width: 350px;
}

.courseCardText {

  font-weight: 700;
  font-size: 24px;
  font-family: 'Heebo', sans-serif;
  color: #1E2452;
}

:root {
  --border-size: 0.125rem;
  --duration: 250ms;
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --font-family: monospace;
  --color-primary: white;
  --color-secondary: #1E2452;
  ;
  --color-tertiary: dodgerblue;
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

* {
  box-sizing: border-box;
}


.multi-button {
  display: flex;
  width: 100%;
  box-shadow: var(--shadow) 4px 4px;
}

.multi-button button {
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  padding:
    calc(var(--space) / 1.125) var(--space) var(--space);
  border: var(--border-size) solid #1E2452;
  ;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  font-size: 1.5rem;
  font-family: 'Heebo', sans-serif;
  text-transform: lowercase;
  text-shadow: var(--shadow) 2px 2px;
  transition: flex-grow var(--duration) var(--ease);
}

.multi-button button+button {
  border-left: var(--border-size) solid #1E2452;
  ;
  margin-left: calc(var(--border-size) * -1);
}

.multi-button button:hover,
.multi-button button:focus {
  flex-grow: 2;
  color: white;
  outline: none;
  text-shadow: none;
  background-color: var(--color-secondary);
}

.multi-button button:focus {
  outline: var(--border-size) dashed var(--color-primary);
  outline-offset: calc(var(--border-size) * -2);
}

.multi-button:hover button:focus:not(:hover) {
  flex-grow: 1;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  outline-color: var(--color-tertiary);
}

.multi-button button:active {
  transform: translateY(var(--border-size));
}

.capsul {
  background-color: #1E2452;
  color: white;
}

.slideMejar {
  height: 200px;
}

.cerocelImg {
  height: 700px;
}

.imageText {
  font-weight: 400;
  font-size: 14px;
  line-height: 80px;
  top: 60%;
}

.slideFontPera {
  font-weight: 400;
  font-size: 14px;
  line-height: 80px;
  top: 75%;
}

.slideFontHeading {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  /* identical to box height */


  color: #FFFFFF;
}

.slideFontPeraTwo {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  
  /* identical to box height, or 24px */


  color: #FFFFFF;
}

.imagePera {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  margin-top: 300px;
  color: #FFFFFF;
}

.imgCardIndian {
  width: 430px;
  height: 365px;
}

.cardIndianHeader {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  color: #1E2452;

}

.cardIndianPera {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 124%;
  /* or 22px */

  text-align: justify;

  color: #666666;

}

.modalItemImg {
  width: 500px;
  height: 470px;
}

.modalItemHeading {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 100%;
  /* identical to box height, or 48px */


  /* Primary Blue */

  color: #1E2452;

}

.modalItemPera {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 150%;
  /* or 39px */

  text-align: justify;

  color: #666666;

}
.logoColor{
  color: white;
}

@media only screen and (max-width:588px) {
  .slideFontPeraTwo {
  display: none;
  }
  .courseBannerImg{
    margin-top: 80px;
  }
  .slideFontHeading {
    margin-top: 40px;
    margin-left: 10px;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  
    line-height: 20px;
    /* identical to box height */
  
  
    color: #ffffff;
  }
  .imageText {
    top: 60%;
  }
  .OurCourse {
    font-family: 'Heebo', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #1E2452;
  }
  .imgCardIndian {
    width: 330px;
    height: 265px;
  }
  .modalItemImg {
    width: 400px;
    height: 300px;
  }
  .modalItemHeading {
    font-size: 40px;
    text-align: center;
  }
  .courseCard {
    width: 350px;
    height: 400px;
  }
  

}

@media only screen and (min-width: 588px) and (max-width:992px) {
  .courseCard {
    width: 350px;
    height: 400px;
  }
  .modalItemHeading {
    font-size: 40px;
    text-align: center;
  }
  .slideFontPeraTwo {
    display: none;
    }
    .slideFontHeading {
      font-family: 'Heebo', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      text-align: center;
      line-height: 45px;
      /* identical to box height */
    
    
      color: #FFFFFF;
    }
    
}

@media (prefers-color-scheme: dark) {
   .OurCourse, .courseCardText, .cardIndianPera, .modalItemPera{
    color: white;
   }
   .cardIndianHeader, .modalItemHeading{
    color: orange;
   }
}