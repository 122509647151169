.cardMajer {
    width: 850px;

}

.recentPost {
    color: rgb(71, 51, 227);
    font-family: 'Heebo', sans-serif;
    text-decoration: none;
}

.title {
    font-family: 'Heebo', sans-serif;
    width: 800px;
    height: 72px;
    left: 140px;
    top: 175px;


    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 100.4%;
}

.btnCol {
    background-color: #F68920;
    border: none;
}

.imgMegar {
    height: 500px;

}

.cardCol{
    background: linear-gradient(90deg, rgba(38,0,66,1) 0%, rgba(82,1,140,1) 44%, rgba(99,0,171,1) 100%);

}
.iconCol{
    color: orange;
}
.cetaText{
    transition: all 0.2s ease-in-out;  
}
.cetaText:hover{
    color: #fdec6e;
    text-shadow:0px 0px 30px #fdec6e;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.blogDes {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    text-align: justify;
    letter-spacing: 0.05em;

    color: #555555;
}

.recentPostPera{
    text-decoration: none;
    text-align: left;
    transition: all 0.2s ease-in-out;  
    border-radius: 10px;
}
.recentPostPera:hover{
    text-decoration: none;
    color: #fdec6e;
    text-shadow:0px 0px 30px #fdec6e;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}


@media only screen and (max-width:588px) {
    .cardMajer {
        width: 400px;
        place-items: center;


    }

    .title {
        font-family: 'Heebo', sans-serif;
        width: 400px;
        height: 72px;
        left: 140px;
        top: 175px;


        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100.4%;
    }

    .imgMegar {
        height: 300px;

    }
}

@media only screen and (min-width: 588px) and (max-width:992px) {
    .cardMajer {
        width: 680px;
        place-items: center;


    }

    .title {
        font-family: 'Heebo', sans-serif;
        width: 800px;
        height: 72px;
        left: 140px;
        top: 175px;

        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100.4%;
    }

    .imgMegar {
        height: 500px;

    }
}


@media (prefers-color-scheme: dark) {

    .title,
    .blogDes,
    .cetaTittle,
    .recentPost {
        color: white;
        font-family: 'Heebo', sans-serif;
    }

    .recentPost {
        color: white;
        font-family: 'Heebo', sans-serif;
        text-decoration: none;
    }

    ;
}