

.text-animation {
  color:#fff;
  opacity:0;
}
.text-animation span {
  position:relative;
  top:10px;
  left:10px;
  font-size:50px;
  font-weight:600;
  opacity:0;
  text-transform:uppercase;
  animation:fade 400ms ease-in-out forwards;
}
.center {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
@keyframes fade {
  0% {
    top:10px;
    left:10px;
    filter:blur(15px);
    opacity:0;
  }
  50% {
    filter:blur(10px);
    opacity:0.9;
  }
  100% {
    top:0px;
    left:0px;
    filter:blur(0px);
    opacity:1;
  }  
}
.mainHading {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 35px;
  color: #1E2452;


 

}
.mainIntro{
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.cardIndex {
  z-index: 500;
}


.container {
  position: relative;
  width: 510px;
  height: 296px;
  border-radius: 15px;
}

.image {
  opacity: 1;
  display: block;
  width: 534px;
  height: 296px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.imgRange {
  color: blue;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 400;
  width: 400;
  opacity: 0;
  transition: .5s ease;
  background-color: #171c1e;
  border-radius: 15px;

}

.container:hover .overlay {
  opacity: 0.8;

}


.text {
  color: white;
  font-size: 16px;
  margin-left: 200px;
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: .5s ease;
  margin-top: 3px;

}

.textTow {
  color: white;
  font-size: 16px;
  margin-left: 200px;
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: .5s ease;
  margin-top: 5px;
  

}

.text:hover {
  font-size: 20px;
  font-weight: 600;
  margin-top: 3px;
  background-color: rgb(252, 238, 238);
  color: black;
  padding: 3px;


}

.textTow:hover {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  background-color: rgb(252, 238, 238);
  color: black;
  padding: 5px;

}

@media only screen and (max-width:588px) {
  .mainHading {
    
    font-size: 40px;

  }
  
.container {
  position: relative;
  width: 334px;
  height: 196px;
  border-radius: 15px;
}

.image {
  opacity: 1;
  display: block;
  width: 334px;
  height: 196px;
  transition: .5s ease;
  backface-visibility: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 196;
  width: 334;
  opacity: 0;
  transition: .5s ease;
  background-color: #171c1e;
  border-radius: 15px;

}


.text {
  top: 5px;
  color: white;
  font-size: 12px;
  margin-left: 120px;
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: .5s ease;
  margin-top: 5px;

}
.textTow {
  color: white;
  font-size: 12px;
  margin-left: 120px;
  margin-top: 10px;
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: .5s ease;
  margin-top: 0px;

}



}

@media only screen and (min-width: 588px) and (max-width:992px) {
  .mainHading {

    font-size: 50px;

  }
}

@media (prefers-color-scheme: dark) {
  .mainHading {

    color: white;  
  }
}