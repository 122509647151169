.courseAllBackground {
    position: relative;
    background-image: url(https://i.ibb.co/v1JJKG5/29822988-q-abstract-3d-background-4-Converted-1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.courseAllBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    /* Adjust the opacity as needed */
}

.HeadingTittleOne {
    position: relative;
    font-family: 'Heebo', sans-serif;
    font-weight: 800;
    font-size: 64px;
    line-height: 35px;
    color: white;
    z-index: 1;
}




.subHeading {
    font-family: 'Heebo', sans-serif;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;

    color: #444444;

}

/* countIitems Start */
.countOne {

    width: 400px;
    height: 180px;
    background: #7E8DFC;
    border-radius: 10px;
}

.countTwo {

    width: 400px;
    height: 180px;
    background: #9680EF;
    border-radius: 10px;
}

.countThree {

    width: 400px;
    height: 180px;
    background: #45AFD9;
    border-radius: 10px;
}

.countText {


    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;

    color: #FFFFFF;
}

.countTextPera {

    width: 260px;
    height: 63px;
    margin-top: 20px;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;

    color: #FFFFFF;
}

/* countIitems end */


/* project start */
.projectText {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;

    color: #1E2452;
}

.projectImg {
    width: 1373px;
    height: 341px;

    border-radius: 30px;
}

.projectTextTwo {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.04em;

    color: #444444;

}

/* project end */


/* lifetime start */
.lifetimeHeading {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 72px;
    /* identical to box height */


    color: #1E2452;
}

.lifetimePera{
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
font-size: 30px;
line-height: 36px;
letter-spacing: 0.04em;

color: #666666;
}

.lifetimeImg{
    width: 692.58px;
height: 390px;
}

/* lifetime end */
.eventTxt{
    font-family: 'Heebo', sans-serif;
    font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 60px;
/* identical to box height */


/* Primary Blue */

color: #1E2452;
}

/* event end */


.boxCard {
    width: 300px;
    height: 520px;
}

.mainHeading {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
    text-align: center;

    /* Primary Blue */

    color: #1E2452;
}

.cardTittle {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;

    /* Primary Blue */

    color: #1E2452;
}

.cardPera {
    font-family: 'Heebo', sans-serif;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */


    color: #666666;
}

.Support {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 87px;
    text-align: center;

    /* Primary Blue */

    color: #1E2452;
}

.supportPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.05em;

    color: #999999;

}

.boxImgOne {
    width: 590px;
    height: 390px;
}

.boxImgOnelist {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.05em;

    color: #333333;
    margin-bottom: 10px;

}

.boxImgTwo {
    width: 1049px;
    height: 390px;
}

.workTittle {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 87px;


    /* Primary Blue */

    color: #1E2452;
}

.workText {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    /* or 57px */

    letter-spacing: 0.03em;

    /* Primary Blue */

    color: #1E2452;
}

@media only screen and (max-width:588px) {
    .mainHeading {

        font-size: 40px;
        line-height: 50px;
    }
    .HeadingTittleOne{
        font-size: 30px;
       
    }

    .workTittle {
        font-size: 40px;
        line-height: 50px;
    }

    .workText {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    .Support {
        font-size: 40px;
        text-align: center;
        line-height: 50px;
    }

    .supportPera {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #999999;

    }

    .subHeading {
        font-family: 'Heebo', sans-serif;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;

        color: #444444;

    }

    .boxImgOne {
        width: 400px;
        height: 300px;
    }

    .boxImgTwo {
        width: 400px;
        height: 300px;
    }

    .boxImgOnelist {

        font-size: 16px;
        text-align: center;

        color: #333333;

    }
 
        .projectText{
            font-size: 40px;
           
        line-height: 76px;
        margin-bottom: 10px;
        text-align: center;
        }
 

}

@media only screen and (min-width: 588px) and (max-width:992px) {
    .mainHeading {
        font-size: 40px;
        line-height: 50px;
    }

    .workTittle {
        font-size: 40px;
        line-height: 50px;
    }

    .workText {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    .Support {
        font-size: 40px;
        text-align: center;
        line-height: 50px;
    }

    .supportPera {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #999999;

    }

    .subHeading {
        font-family: 'Heebo', sans-serif;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;

        color: #444444;

    }

    .boxImgOne {
        width: 400px;
        height: 300px;
    }

    .boxImgTwo {
        width: 400px;
        height: 300px;
    }

    .boxImgOnelist {

        font-size: 16px;

        text-align: center;
        color: #333333;

    }
    .projectText{
        font-size: 36px;
       
    line-height: 6px;
    }
}

@media (prefers-color-scheme: dark) {
    .subHeading {


        color: white;

    }
    .projectText {
        color: white;
    }
    .projectTextTwo{
        color: white;
    }
    .lifetimePera{
        color: white;
    }
    .lifetimeHeading{
        color: white;
    }
    .eventTxt{
        color: white;
    }



    .mainHeading {
        color: white;
    }

    .cardTittle {
        color: white;
    }

    .cardPera {
        color: white;
    }

    .Support,
    .supportPera,
    .boxImgOnelist,
    .workTittle,
    .workText {
        color: white;
    }
}