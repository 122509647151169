.leftLayOut {
    margin-top: 30px;

    width: 740px;
}

.rightLayOut {
    margin-top: 30px;
    height: 500px;
    width: 590px;
}

.courseImg {
    height: 320px;
    width: 100%;
}

.courseName {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 100%;
    font-family: 'Heebo', sans-serif;
}

.courseDescription {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;


    /* or 35px */

    text-align: justify;
    letter-spacing: 0.05em;
    font-family: 'Heebo', sans-serif;
    color: #1E2452;

}

.courseDespera {
    width: 680px;
    height: 340px;


    font-family: 'Heebo', sans-serif;
    font-weight: 400;

    font-size: 22px;
    line-height: 160%;
    /* or 35px */

    text-align: justify;
    letter-spacing: 0.05em;

    color: #000000;

}

.tickmark {
    width: 20px;
    height: 20px;

    transition-duration: .6s;

}

.OutlineTittle {
    font-size: 22px;
    font-family: 'Heebo', sans-serif;
    color: #1E2452;
    font-weight: 600;
}

.OutlilnePera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 160%;
    width: 650px;
    /* or 35px */

    letter-spacing: 0.05em;

    color: #444444;

}

.logo {
    width: 50px;
    height: 50px;
}

.logoText {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */


    color: #000000;

}

.multiButtonSize {
    font-family: 'Heebo', sans-serif;
    width: 585px;
}

.formPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* or 16px */


    color: #999999;
}

.formName {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 16px */


    color: #666666;

}
.left{
    
   
    width: 600px;
}
.right{
   
    
    width: 500px;
    
}

@media only screen and (max-width:588px) {

.courseImg{
    width: 400px;
    height: 250px;
}
.courseName{
    font-size: 30px;
    text-align: left
}
.courseDescription{
    font-size: 30px;
}
.courseDespera{
    width: 350px;
   

}
.OutlilnePera{
    width: 250px;
    font-size: 20px;
}
.courseDescriptionTwo{
    margin-top: 510px;
}
.OutlineTittle{
    width: 370px;
}
.courseDescriptioThree{
    font-size: 30px;
    text-align: left;
    width: 350px;
    
}

.tick-mark {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%238bc34a"%3E%3Cpath d="M9 16.2L4.8 12c-.6-.6-.6-1.6 0-2.2.6-.6 1.6-.6 2.2 0l2.5 2.5 6.7-6.7c.6-.6 1.6-.6 2.2 0 .6.6.6 1.6 0 2.2l-8.8 8.8c-.6.5-1.6.5-2.2-.1z"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
  }
  
.logoGrid{
    display: flex;
    
}
.multiButtonSize {
    width: 370px;
}
.buttonAll{
    width: 350px;
}
.courseDescriptionFive{
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: justify;
    font-family: 'Heebo', sans-serif;
    color: #1E2452;
}
.formPera{
    width: 350px;
}

.leftIcon{
    width: 326px;
   height: 326px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(38,0,66);
  background: linear-gradient(90deg, rgba(38,0,66,1) 0%, rgba(82,1,140,1) 44%, rgba(99,0,171,1) 100%);
  transition: all 0.2s ease-in-out;  
}

  }
  
  @media only screen and (min-width: 588px) and (max-width:992px) {

      
  }

  @media (prefers-color-scheme: dark) {
    .courseName, .courseDescription, .courseDespera, .courseDescription, .OutlilnePera, .logoText {
       color: white;
    }
    .OutlineTittle{
        color: orange;
    }
    }

