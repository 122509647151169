.footerBackground {
    background-color: #1E2452;
    height: 350px;

}

.Maintittle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 32px */

    font-family: 'Heebo', sans-serif;
    color: #FFFFFF;

}

.mainPera {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #FFFFFF;
}

.mainPeraTwo {
    color: #1E2452;
}

.footerPipe {

    background: #222222;
}

.mainPeraThree {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #FFFFFF;
}

@media only screen and (max-width:588px) {
    .footerBackground {
        background-color: #1E2452;
        height: 500px;
        top: 200px;
       

    }


    .Maintittle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 32px */

        font-family: 'Heebo', sans-serif;
        color: #FFFFFF;
        

    }

    .mainPera {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 20px */


        color: #FFFFFF;
        
    }

    .comPera {
        margin-top: 15px;
        
    }

}

@media only screen and (min-width: 588px) and (max-width:992px) {
    .footerBackground {
        background-color: #1E2452;
        height: 500px;
        
    

    }


    .Maintittle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 32px */

        font-family: 'Heebo', sans-serif;
        color: #FFFFFF;
        

    }

    .mainPera {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 20px */
        

        color: #FFFFFF;
    }

    .comPera {
        margin-top: 15px;
        
    }
}