.imgSizeTool {
    width: 1340px;
    transform-style: preserve-3d;
    transform: perspective(800px);
}
.TextMainThree{
  font-family: 'Heebo', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
}

.HomeAllBackground {
  position: relative;
  background-image: url(https://i.ibb.co/3W1bhhg/346164527-924854965477094-27115295209463525-n.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
}

.HomeAllBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  border-radius: 12px;
  /* Adjust the opacity as needed */
}

.HeadingMainOne{
  position: relative;
  font-family: 'Heebo', sans-serif;
  font-weight: 800;
  font-size: 90px;
  line-height: 30px;
  color: white;
  z-index: 1;
}


@-webkit-keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 1;
    text-shadow: none;
  }
  14.9% {
    opacity: 1;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 1;
    text-shadow: none;
  }
  24.9% {
    opacity: 1;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 1;
    text-shadow: none;
  }
  39.9% {
    opacity: 1;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 1;
    text-shadow: none;
  }
  44.9% {
    opacity: 1;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: none;
  }
  69.4% {
    opacity: 1;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 1;
    text-shadow: none;
  }
  79.4% {
    opacity: 1;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 1;
    text-shadow: none;
  }
  89.8% {
    opacity: 1;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 1;
    text-shadow: none;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 1;
    text-shadow: none;
  }
  14.9% {
    opacity: 1;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 1;
    text-shadow: none;
  }
  24.9% {
    opacity: 1;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 1;
    text-shadow: none;
  }
  39.9% {
    opacity: 1;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 1;
    text-shadow: none;
  }
  44.9% {
    opacity: 1;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: none;
  }
  69.4% {
    opacity: 1;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 1;
    text-shadow: none;
  }
  79.4% {
    opacity: 1;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 1;
    text-shadow: none;
  }
  89.8% {
    opacity: 1;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 1;
    text-shadow: none;
  }
  100% {
    opacity: 1;
  }
}



.textMainAnim{

  -webkit-animation: text-flicker-out-glow 2.5s linear infinite both;
	        animation: text-flicker-out-glow 2.5s linear infinite both;
         
       
            text-transform: none;
 text-align: left;

/* margin-right: 450px; */
         
}


.imgOverlyOut {
    z-index: -1;
}

.imgSizeTool2 {
    top: 0;
    width: 100px;
    height: 100px;
}

.imgOverlyOut:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    display: inline-block;
    background: #1E2452;
    opacity: 0.7;


}

.homeTwoText {
    font-family: 'Heebo', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-weight: 700;
    font-size: 94px;
    line-height: 75px;
    /* identical to box height */


    color: #FFFFFF;
    top: 30%;
}

.slideButton{
  margin-top: 30px;
  width: 150px;
  height: 50px;
  background: rgb(38,0,66);
  background: linear-gradient(90deg, rgb(100, 69, 186) 0%, rgba(82,1,140,1) 44%, rgba(99,0,171,1) 100%);
  padding: 5px;
  border-radius: 60px;
  font-size: 18px;
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease-in-out;  
 
}
.slideButton:hover{
  color: #ffffff;
  text-shadow:0px 0px 30px #fffef6;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.3s ease-in;

 
}

.slideOne {
    z-index: -1;
}

.slideFont {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    
    text-align: left;
    margin-top: 30px;
    color: #FFFFFF;

}

.thirdText{
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  text-align: left;
  font-size: 40px;
  line-height: 52px;
  position: absolute;
/* identical to box height */
z-index: 1;
letter-spacing: 0.04em;
  margin-top: 20px;
  color: white;
  font-weight: 400;
}

.clientDot {
    font-family: 'Heebo', sans-serif;
    
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 18px;
   
    color: #FFFFFF;

}

.slideBox {
    margin-top: 100px;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400');

.ani{
   
    text-align:center;
    font-family:'Roboto';
    font-size: 56px;
    font-weight:100;

}
.dropping-texts {
  display: inline-block;
  width: 600px;
  text-align: left;
 
  height: 36px;
  vertical-align: -2px;
}

.dropping-texts > div {
  font-size: 14px;
 
  opacity:0;
  margin-left:-30px;
  position:absolute;
  font-weight:300;   
 
}

.dropping-texts > div:nth-child(1) {
  animation: roll 5s linear infinite 0s;
}
.dropping-texts > div:nth-child(2) {
  animation: roll 5s linear infinite 1s;
}
.dropping-texts > div:nth-child(3) {
  animation: roll 5s linear infinite 2s;
}
.dropping-texts > div:nth-child(4) {
  animation: roll2 5s linear infinite 3s;
}

@keyframes roll {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  20% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  27% {
    font-size:0px;
    opacity:0.5;
    margin-left:20px;
    margin-top:100px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes roll2 {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  30% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  37% {
    font-size:1500px;
    opacity:0;
    margin-left:-1000px;
    margin-top:-800px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes bg {
  0% {background: #ff0075;}
  3% {background: #0094ff;}
  20% {background: #0094ff;}
  23% {background: #b200ff;}
  40% {background: #b200ff;}
  43% {background: #8BC34A;}
  60% {background: #8BC34A;}
  63% {background: #F44336;}
  80% {background: #F44336;}
  83% {background: #F44336;}
  100% {background: #F44336;}
}

@media only screen and (max-width:588px) {
 .textMainAnim{
margin-top: 10px;


 }  
 .HomeAllBackground{
  width: 500px;
  height: 250px;
 }
 .HeadingMainOne{
  font-size: 36px;
  text-align: center;
  margin-right: 120px;
 }
 .textFourth {
  display: none;
 }
    
    .homeTwoText {
        margin-top: 100px;
        font-family: 'Heebo', sans-serif;
        font-weight: bold;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
       
        text-align: center;
        line-height: 15px;
        /* identical to box height */


        color: #FFFFFF;
        top: 10%;
    }

    .slideFont {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        display: none;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.13em;

        color: #FFFFFF;

    }
    
    .clientDot {
        font-family: 'Heebo', sans-serif;


        font-style: normal;
        font-weight: 200;
        font-size: 12px;
        line-height: 8px;

        color: #FFFFFF;
       

    }
    .thirdText{
      font-family: 'Heebo', sans-serif;
      font-style: normal;
      text-align: left;
      font-size: 15px;
    /* identical to box height */
      margin-left: 20px;
      color: white;
      font-weight: 400;
     display: none;
    }
    .textFourth{
      margin-left: 20px;
      position: absolute;
      z-index: 1;
    }
}

@media only screen and (min-width: 588px) and (max-width:992px) {

    .homeTwoText {
        font-family: 'Heebo', sans-serif;
        font-weight: bold;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 75px;
        /* identical to box height */
        margin-left: 80px;

        color: #FFFFFF;
        top: 30%;
    }
    .textFourth {
      display: none;
    }
    .thirdText{
      font-family: 'Heebo', sans-serif;
      font-style: normal;
      text-align: center;
      font-size: 20px;
      margin-left: 80px;
      
      display: none;
    /* identical to box height */
    
     letter-spacing: 0.04em;
      
      color: white;
      font-weight: 400;
    }
    .textMainAnim {
      margin-left: 230px;
    }
   

    .slideFont {
        font-family: 'Heebo', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.13em;

        color: #FFFFFF;

    }

    .clientDot {
        font-family: 'Heebo', sans-serif;
        margin-top: 10px;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;

        color: #FFFFFF;

    }
}