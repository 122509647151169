.view{
  
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.imgMejarTwo{
    width: 200px;
    height: 100px;
}